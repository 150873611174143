// import logo from './logo.svg';
import "./App.css";
import Navbar from "./navbar"; // Import the Navbar component
import BlogList from "./blog/BlogList";
import Footer from "./footer"; // Import the Footer component
import BlogDetails from "./blog/BlogDetails";
import CheatSheet from "./cheatSheet/CheatSheet"; // Import the new component
import Newsletter from "./newsletter/newsletter";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import About from "./legal/About";
import Imprint from "./legal/Imprint";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/cheat-sheet" element={<CheatSheet />} />
        <Route path="/privacy policy" element={<PrivacyPolicy />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/about" element={<About />} />
        {/* Redirect / to /blog */}
        <Route path="/" element={<Navigate to="/blog" />} />
      </Routes>
      <Newsletter />
      <Footer />
    </Router>
  );
}

export default App;
