import React from "react";

const renderNode = (contentNode, index) => {
  switch (contentNode.nodeType) {
    case "paragraph":
      return (
        <p key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </p>
      );
    case "heading-1":
      return (
        <h1 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h1>
      );
    case "heading-2":
      return (
        <h2 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h2>
      );
    case "heading-3":
      return (
        <h3 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h3>
      );
    case "heading-4":
      return (
        <h4 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h4>
      );
    case "heading-5":
      return (
        <h5 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h5>
      );
    case "heading-6":
      return (
        <h6 key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </h6>
      );
    case "list-item":
      return (
        <li key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </li>
      );
    case "unordered-list":
      return (
        <ul key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </ul>
      );
    case "ordered-list":
      return (
        <ol key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </ol>
      );
    case "blockquote":
      return (
        <blockquote key={index} className="code-highlight">
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </blockquote>
      );
    case "hr":
      return <hr key={index} />;
    case "embedded-asset-block":
      return (
        <img
          key={index}
          src={contentNode.data.target.fields.file.url}
          alt={contentNode.data.target.fields.title}
        />
      );
    case "embedded-entry-block":
      return (
        <a key={index} href={contentNode.data.target.fields.url}>
          {contentNode.data.target.fields.title}
        </a>
      );
    case "code":
      return (
        <code key={index}>
          {contentNode.content.map((childNode, idx) =>
            renderNode(childNode, idx)
          )}
        </code>
      );
    case "table": // Handle table rendering
      return (
        <table
          key={index}
          style={{
            borderCollapse: "collapse",
            width: "auto",
            textAlign: "left",
            border: "1px solid black",
          }}
        >
          <tbody>
            {contentNode.content.map((rowNode, rowIndex) => (
              <tr
                key={rowIndex}
                style={{
                  height: "auto", // Adjust row height as needed
                }}
              >
                {rowNode.content.map((cellNode, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      border: "1px solid black",
                      // whiteSpace: "nowrap", // Prevent text wrapping within cells
                      overflow: "hidden", // Hide content that overflows the cell
                      textOverflow: "ellipsis", // Show ellipsis (...) for overflowing content
                      padding: "0px 10px",
                    }}
                  >
                    {cellNode.content.map((childNode, idx) =>
                      renderNode(childNode, idx)
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );

    case "text":
      // Handle bold, italic, and underline styles
      if (contentNode.marks.some((mark) => mark.type === "bold")) {
        return <strong key={index}>{contentNode.value}</strong>;
      } else if (contentNode.marks.some((mark) => mark.type === "italic")) {
        return <em key={index}>{contentNode.value}</em>;
      } else if (contentNode.marks.some((mark) => mark.type === "underline")) {
        return (
          <u key={index} className="special-underline">
            {contentNode.value}
          </u>
        );
      } else {
        return contentNode.value;
      }
    default:
      return null;
  }
};

const BlogContentRenderer = ({ content }) => {
  return (
    <div>
      {content.map((contentNode, index) => renderNode(contentNode, index))}
    </div>
  );
};

export default BlogContentRenderer;
