import React, { useState, useEffect } from "react";
import "./CheatSheet.css"; // Import the CSS file

const LandingPage = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://gumroad.com/js/gumroad-embed.js";
    script.async = true;
    document.body.appendChild(script);
    setScriptLoaded(true);

    return () => {
      if (scriptLoaded) {
        document.body.removeChild(script);
      }
    };
  }, [scriptLoaded]);

  return (
    <div className="landing-page-container">
      <div className="header">
        <h1>Unlock Your Full Excel Potential</h1>
        <p>Master Excel with ease using our comprehensive Cheat Sheet.</p>
        <h2>
          Coming soon! Subscribe to the newsletter to stay up-to-date about the
          future release!
        </h2>
      </div>

      {/* <div className="gumroad-product-embed">
        <a href="https://bluelaneventures.gumroad.com/l/cheat-sheet">
          Loading...
        </a>
      </div> */}
    </div>
  );
};

export default LandingPage;
