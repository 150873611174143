import React, { useState, useEffect } from "react";
import { createClient } from "contentful";

const Footer = () => {
  const [footerLinks, setfooterLinks] = useState([]);
  const [copyrightText, setCopyrightText] = useState("");

  useEffect(() => {
    const client = createClient({
      space: "4wxah41ka2oo",
      accessToken: "KnIMExE3pTn5fSPRd0RpUryrfaijOdgtTiM_H7Qbl7Q",
    });

    const getFooterContent = async () => {
      try {
        const response = await client.getEntries({
          "sys.id": "7D8RyGtN14wEbfxvi5D0Wt",
        });
        console.log("Response from Contentful:", response);
        const footerEntry = response.items[0];
        setfooterLinks(footerEntry.fields.footerLinks);
        setCopyrightText(footerEntry.fields.copyrightText);
      } catch (error) {
        console.error("Error fetching footer content:", error);
        console.error("Error fetching footer content:", error);
      }
    };

    getFooterContent();
  }, []);

  return (
    <footer>
      <ul className="footer">
        {footerLinks.map((item, index) => (
          <li key={index}>
            <a href={`/${item.toLowerCase()}`}>{item}</a>
          </li>
        ))}
      </ul>
      {copyrightText && <div className="copyrightText">{copyrightText}</div>}
    </footer>
  );
};

export default Footer;
