import React from "react";
import "./legal.css";

function Imprint() {
  return (
    <div>
      <header>
        <h1>Imprint</h1>

        <p>
          Aron Penn
          <br />
          Pestalozzistra&szlig;e 28
          <br />
          80469 M&uuml;nchen
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: 01799303717
          <br />
          E-Mail: aronpenn@icloud.com
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>
          Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
        </p>
      </header>
    </div>
  );
}

export default Imprint;
