import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { createClient } from "contentful";
import BlogContentRenderer from "./BlogContentRenderer";
// import CommentSection from "../CommentSection/CommentSection";
import "./BlogDetails.css";

function BlogDetails() {
  const { id } = useParams(); // Access the 'id' parameter from the URL
  const [selectedPost, setSelectedPost] = useState(null);
  const [recommendedPosts, setRecommendedPosts] = useState([]);
  // const [postComments, setPostComments] = useState({}); // State for post comments

  useEffect(() => {
    const client = createClient({
      space: "4wxah41ka2oo",
      accessToken: "KnIMExE3pTn5fSPRd0RpUryrfaijOdgtTiM_H7Qbl7Q",
    });

    async function fetchBlogPost() {
      try {
        const entry = await client.getEntry(id);
        setSelectedPost(entry);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    }
    async function fetchRecommendedPosts() {
      try {
        const entries = await client.getEntries({
          content_type: "blogHomepage", // Adjust to match your Contentful content type
          order: "-fields.publishDate",
          limit: 3,
          "sys.id[ne]": id,
        });
        setRecommendedPosts(entries.items);
      } catch (error) {
        console.error("Error fetching recommended posts:", error);
      }
    }

    fetchBlogPost();
    fetchRecommendedPosts();

    return () => {
      // Cleanup function (optional)
    };
  }, [id]); // Dependency array with 'id'

  return (
    <div className="blog-details-fullscreen">
      <div className="back-button-container">
        {/* Use React Router's Link component to navigate back to blog list */}
        <Link to="/" className="back-button">
          Return to All Blogs
        </Link>
      </div>
      {selectedPost && (
        <div>
          <h1>{selectedPost.fields?.BlogTitle}</h1>
          <h4>
            {selectedPost.fields?.publishDate} -{" "}
            {selectedPost.fields?.blogAuthor}
          </h4>
          {/* <img src={selectedPost.fields?.BlogMedia?.fields?.file?.url} alt={selectedPost.fields?.BlogMedia?.fields?.title} /> */}
          <BlogContentRenderer
            content={selectedPost.fields?.BlogContentText?.content}
          />
          <p>{selectedPost.fields?.FullBlogContent}</p>
          {/* Render comment section for this post */}
          {/* <CommentSection
            postId={selectedPost.sys.id}
            comments={postComments[selectedPost.sys.id] || []}
            setComments={(newComments) => {
              setPostComments({
                ...postComments,
                [selectedPost.sys.id]: newComments,
              });
            }}
          /> */}
        </div>
      )}
      {/* Recommended articles section */}
      {recommendedPosts.length > 0 && (
        <div className="recommended-section">
          <h2>Recommended Articles</h2>
          <div className="recommended-posts-container">
            {recommendedPosts.map((post) => (
              <div key={post.sys.id} className="recommended-post">
                <Link to={`/blog/${post.sys.id}`}>
                  <h3>{post.fields?.BlogTitle}</h3>
                </Link>
                <p>
                  {post.fields?.publishDate} | {post.fields?.blogAuthor}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogDetails;
