// newsletter.js

import React, { useState } from "react";
import "./newsletter.css"; // Import the CSS file

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter your email address.");
      return;
    }

    try {
      const response = await fetch(
        "/.netlify/functions/subscribe", // Relative path to your Netlify function
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }), // Ensure payload is correctly serialized as JSON
        }
      );

      if (response.ok) {
        alert("Thank you for subscribing!");
        setEmail(""); // Clear the email input field after successful submission
      } else {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="newsletter-section">
      <div className="newsletter-container">
        <form className="newsletter-form" onSubmit={handleSubmit}>
          <p>Stay updated whenever there's a new blog post!</p>
          <input
            className="newsletter-input"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={handleEmailChange}
          />
          <button className="newsletter-button" type="submit">
            Follow
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
