import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import BlogContentRenderer from "./BlogContentRenderer"; // Adjust the file path as needed
import { Helmet, HelmetProvider } from "react-helmet-async";
// import CommentSection from "../CommentSection/CommentSection";

function BlogList() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const [postComments, setPostComments] = useState({});

  const client = createClient({
    space: "4wxah41ka2oo",
    accessToken: "KnIMExE3pTn5fSPRd0RpUryrfaijOdgtTiM_H7Qbl7Q",
  });

  useEffect(() => {
    if (blogPosts.length === 0) {
      async function getAllBlogPosts() {
        try {
          const entries = await client.getEntries();
          const blogEntries = entries.items.filter(
            (entry) => entry.sys.contentType.sys.id === "blogHomepage"
          );
          setBlogPosts(blogEntries);
          if (blogEntries.length > 0 && !selectedPost) {
            setSelectedPost(blogEntries[0]); // Set initial selected post
          }
        } catch (error) {
          console.log("Error fetching blog posts:", error);
        }
      }

      getAllBlogPosts(); // Fetch posts only if blogPosts state is empty
    }
  }, [blogPosts, selectedPost, client]); // Dependencies include blogPosts and selectedPost

  // Handle click on a blog post
  const handlePostClick = (post) => {
    if (window.innerWidth <= 768) {
      // Open blog details in new window on mobile
      window.location.href = `/blog/${post.sys.id}`;
    } else {
      // Set selected post on desktop view
      setSelectedPost(post);
    }
  };

  const openInNewWindow = () => {
    if (selectedPost && selectedPost.sys && selectedPost.sys.id) {
      const url = `/blog/${selectedPost.sys.id}`;
      window.open(url, "_blank", "noopener noreferrer");
    } else {
      console.error("Selected post is invalid or missing ID");
      // Handle error or provide feedback to the user
    }
  };

  return (
    <HelmetProvider>
      <>
        <Helmet>
          {selectedPost && (
            <>
              <title>{selectedPost.fields?.BlogTitle}</title>
              <meta
                name="description"
                content={selectedPost.fields?.shortBlogSummary}
              />
              <meta
                property="og:title"
                content={selectedPost.fields?.BlogTitle}
              />
              <meta
                property="og:description"
                content={selectedPost.fields?.shortBlogSummary}
              />
            </>
          )}
        </Helmet>
        <header>
          <h1>
            Welcome to the <span id="underline"> Blackline Ventures'</span>{" "}
            Blog!
          </h1>
        </header>

        <div className="blog-container">
          <div className="blog-list">
            <div className="search-field">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search blog posts..."
              />
            </div>

            {blogPosts
              .filter((post) => {
                const titleMatch =
                  post.fields?.BlogTitle &&
                  post.fields.BlogTitle.toLowerCase().includes(
                    searchQuery.toLowerCase()
                  );
                const summaryMatch =
                  post.fields?.shortBlogSummary &&
                  post.fields.shortBlogSummary
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());

                return titleMatch || summaryMatch;
              })
              .map((post) => (
                <div
                  key={post.sys.id}
                  onClick={() => handlePostClick(post)}
                  className={`blog-post ${
                    selectedPost && selectedPost.sys.id === post.sys.id
                      ? "selected"
                      : ""
                  }`}
                >
                  {/* <img src={post.fields?.BlogMedia?.fields?.file?.url} alt={post.fields?.BlogMedia?.fields?.title} />     Hier Könnte man ggf. noch ein kleines Bild anzeigen in der Blog-List Seite.*/}
                  <div>
                    <h2>{post.fields?.BlogTitle}</h2>
                    <h4>
                      {post.fields?.publishDate} - {post.fields?.blogAuthor}
                    </h4>
                    <p>{post.fields?.shortBlogSummary}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="blog-details">
            {selectedPost && (
              <div>
                <div className="title-section-with-fullscreen-icon">
                  <div className="blog-details-title-section">
                    <h2>{selectedPost.fields?.BlogTitle}</h2>

                    <h4>
                      {selectedPost.fields?.publishDate} -{" "}
                      {selectedPost.fields?.blogAuthor}
                    </h4>
                  </div>
                  <div className="fullscreen-button">
                    {/* Use a wrapper element to contain the image and tooltip */}
                    <button onClick={openInNewWindow}>
                      <div className="tooltip-container">
                        {/* Image element */}
                        <img src="/fullscreen_icon.png" alt="Fullscreen" />

                        {/* Tooltip text */}
                        <span className="tooltip-image-text">
                          Open in new window
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                {/* <img src={selectedPost.fields?.BlogMedia?.fields?.file?.url} alt={selectedPost.fields?.BlogMedia?.fields?.title} /> */}
                <BlogContentRenderer
                  content={selectedPost.fields?.BlogContentText?.content}
                />
                <p>{selectedPost.fields?.FullBlogContent}</p>
                {/* Render comment section for this post */}

                {/* <CommentSection
                postId={selectedPost.sys.id}
                comments={postComments[selectedPost.sys.id] || []}
                setComments={(newComments) => {
                  setPostComments({
                    ...postComments,
                    [selectedPost.sys.id]: newComments,
                  });
                }}
              /> */}
              </div>
            )}
          </div>
        </div>
      </>
    </HelmetProvider>
  );
}

export default BlogList;
