import React from "react";
import "./About.css"; // Import the CSS file for styling

function About() {
  return (
    <div>
      <header>
        <h1>About this Blog & Blackline Ventures</h1>
        <div className="introduction">
          <p>
            Welcome to the Blackline Ventures Blog! At Blackline Ventures, we
            dive deep into Excel, PowerPoint, Word, and other useful programs to
            help boost your productivity!{" "}
            <b>
              {" "}
              PS: All tips and shortcuts are optimized for WINDOWS, English
              formulas and european thousands seperator (Period = ".").
            </b>
          </p>
        </div>
      </header>
      <div className="split-section">
        <div className="split-section-1">
          <div className="card">
            <img src="/AboutImage3.png" alt="Aron" style={{ width: "200px" }} />
            <div className="container">
              <h2>Aron</h2>
              <p>Blog Founder</p>
              {/* <button className="button">E-Mail</button> */}
            </div>
          </div>
        </div>
        <div className="split-section-2">
          <p>
            <b>Hey everyone! </b>
          </p>
          <p>
            I'm Aron, founder of this blog. I use Excel, PowerPoint and Word
            each day at work. I love the programs, and over the time I have
            learned valuable tricks and developed productivity hacks to improve
            the usage of these programs - for personal use as well as work
            related.
          </p>
          <p>
            In these blog posts, I will share my best tips so you will be more
            efficient and happy when using these programs. Please always feel
            free to ask about certain topics, I would love to write a blog post
            about it to answer your question!
          </p>
          <p>
            (Once the comment section is set up - ) Feel free to engage with the
            community, ask questions, and share your insights. Let's make
            Blackline Ventures a collaborative space for learning and growth!
          </p>
          <p>
            Last but not least, I am working on a Cheat-Sheet so you can improve
            your shortcuts, techniques and formulas in the long run! This is
            still in the works, but feel free to subscribe to the newsletter to
            learn about new blog posts and the launch of the Cheat-Sheet!
          </p>
          <p>
            <b>Why Blackline Ventures?</b>
          </p>
          <p>
            First of all, I thought the name was kinda cool! Also, black lines
            are mostly used in Excel: Borders, gridlines, and so on... You get
            the rest ;)
          </p>
          <p>
            Thank you all for checking in and please have the best time here!
            Aron ;)
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
